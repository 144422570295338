<template>
  <div v-if="is_loading">
    <b-spinner variant="secondary"></b-spinner>
  </div>
  <b-row class="gy-2 mb-2" id="candidate-list">
    <b-col lg="12" v-for="(item, index) of data" :key="index">
      <b-card
        no-body
        class="mb-0"
        :class="{ 'bg-soft-secondary': item.developer }"
      >
        <b-card-body>
          <b-row class="align-items-center">
            <b-col sm="12" lg="4">
              <div class="d-lg-flex align-items-center">
                <div class="">
                  <h5
                    class="fs-16 mb-0"
                    :class="{
                      'text-secondary fw-medium': item.developer,
                      'text-muted': !item.developer,
                    }"
                  >
                    {{ item.endpoint.split("interrail.uz/")[1] }}
                  </h5>
                </div>
              </div>
            </b-col>
            <b-col sm="6" lg="1">
              <div class="text-center">
                <h5 class="fs-16 mb-0">
                  <i class="ri-repeat-2-fill align-middle me-1"></i>
                  {{ item.repetition }}
                </h5>
              </div>
            </b-col>
            <b-col sm="6" lg="2">
              <div class="text-center">
                <h5 class="fs-16 mb-0">
                  {{ item.count }}
                  queries
                </h5>
              </div>
            </b-col>
            <b-col sm="6" lg="1">
              <div class="text-center">
                <h5 class="fs-16 mb-0">
                  {{
                    item.before_optimization_count
                      ? item.before_optimization_count
                      : 0
                  }}
                </h5>
              </div>
            </b-col>
            <b-col sm="6" lg="1">
              <div class="text-end">
                <h5 class="fs-16 mb-0">
                  {{ item.optimized_count ? item.optimized_count : 0 }}
                </h5>
              </div>
            </b-col>
            <b-col sm="6" lg="2">
              <div class="text-end" v-if="item.developer">
                <b-button
                  @click="markAsOptimized(item.id)"
                  size="sm"
                  class="me-2"
                  variant="outline-secondary"
                >
                  Mark as optimized
                </b-button>
                <h5 class="d-inline-block text-secondary fs-16 mb-0">
                  {{ item.developer }}
                </h5>
              </div>
              <div class="text-end" v-else>
                <b-button size="sm"> Assign </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import axios from "axios";

export default {
  name: "devs_index",
  data() {
    return {
      data: [],
      is_loading: true,
    };
  },
  methods: {
    async getData() {
      this.is_loading = true;
      try {
        let response = await axios.get("/action_log/queries/");
        this.data = response.data.results;
      } catch {
        alert("Something went wrong");
        this.data = [];
      }
      this.is_loading = false;
    },
    async markAsOptimized(query_id) {
      const confirmed = window.confirm(
        "Are you sure you want to mark it as optimized ?"
      );
      if (!confirmed) {
        this.showJoke();
        return;
      }
      try {
        let response = await axios.post(
          "/action_log/mark_query_as_optimized/",
          {
            query_id: query_id,
          }
        );
        try {
          alert(response.data.message);
        } catch {
          alert("optimized");
        }
        await this.getData();
      } catch {
        alert("error");
      }
    },
    showJoke() {
      const jokes = [
        "Why did the developer refuse to mark the views as optimized? Because they were afraid of being haunted by unoptimized code! 👻",
      ];

      const randomJoke = jokes[Math.floor(Math.random() * jokes.length)];
      alert(randomJoke);
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
